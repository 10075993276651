<template>
    <div class="admin-news">
      <h1 class="title">News Management</h1>
  
      <!-- Haber Ekleme Formu -->
      <form @submit.prevent="addNews" class="news-form">
        <h2>Add News</h2>
        <input v-model="newNews.title" type="text" placeholder="Title" required />
        <input v-model="newNews.subtitle" type="text" placeholder="Subtitle" required />
        <input @change="onFileChange" type="file" accept="image/*" />
        <textarea v-model="newNews.content" placeholder="Content" required></textarea>
        <button type="submit" class="add-button">Add News</button>
      </form>
  
      <!-- Haber Tablosu -->
      <h2>News List</h2>
      <table class="news-table">
        <thead>
          <tr>
            <th>Image</th>
            <th>Title</th>
            <th>Subtitle</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="news in newsList" :key="news._id">
            <td><img :src="news.img" alt="News Image" class="news-img"></td>
            <td>{{ news.title }}</td>
            <td>{{ news.subtitle }}</td>
            <td>
              <button @click="editNews(news)" class="edit-button">Edit</button>
              <button @click="deleteNews(news._id)" class="delete-button">Delete</button>
            </td>
          </tr>
        </tbody>
      </table>
  
      <!-- Düzenleme Modali -->
      <div v-if="editMode" class="modal-overlay">
        <div class="modal-content">
          <h3>Edit News</h3>
          <input v-model="editNewsData.title" type="text" placeholder="Title" />
          <input v-model="editNewsData.subtitle" type="text" placeholder="Subtitle" />
          <input @change="onFileChange" type="file" accept="image/*" />
          <textarea v-model="editNewsData.content" placeholder="Content"></textarea>
          <button @click="updateNews" class="save-button">Save Changes</button>
          <button @click="cancelEdit" class="close-button">Cancel</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        newsList: [],
        newNews: {
          title: '',
          subtitle: '',
          content: '',
          img: null
        },
        editNewsData: null,
        editMode: false
      };
    },
    mounted() {
      this.fetchNews();
    },
    methods: {
      async fetchNews() {
        const response = await axios.get('https://play.rivobit.com/news');
        this.newsList = response.data;
      },
      onFileChange(e) {
        const file = e.target.files[0];
        if (file) {
          this.newNews.img = file;
        }
      },
      async addNews() {
        const formData = new FormData();
        formData.append('title', this.newNews.title);
        formData.append('subtitle', this.newNews.subtitle);
        formData.append('content', this.newNews.content);
        formData.append('img', this.newNews.img);
  
        await axios.post('https://play.rivobit.com/news/add', formData);
        this.fetchNews();
      },
      editNews(news) {
        this.editNewsData = { ...news };
        this.editMode = true;
      },
      async updateNews() {
        const formData = new FormData();
        formData.append('title', this.editNewsData.title);
        formData.append('subtitle', this.editNewsData.subtitle);
        formData.append('content', this.editNewsData.content);
        if (this.editNewsData.img) formData.append('img', this.editNewsData.img);
  
        await axios.put(`https://play.rivobit.com/news/${this.editNewsData._id}`, formData);
        this.fetchNews();
        this.editMode = false;
      },
      async deleteNews(id) {
        await axios.delete(`https://play.rivobit.com/news/${id}`);
        this.fetchNews();
      },
      cancelEdit() {
        this.editMode = false;
      }
    }
  };
  </script>
  
  <style scoped>
  .admin-news {
    
    padding: 20px;
    font-family: Arial, sans-serif;
    
    border-radius: 8px;
    
  }
  
  .title {
    text-align: center;
    background: linear-gradient(255deg, #00ffc2 0%, #00aa6d 100%), #a6cae8;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    margin-bottom: 20px;
  }
  
  .news-form {
    margin-bottom: 20px;
    color: #8bacc8;
  }
  
  input[type="text"], textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    background: #1a4f63;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .add-button {
    background: linear-gradient(255deg, #00ffc2 0%, #00aa6d 100%);
    color: white;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 16px;
  }
  

  
  .news-table {
    width: 100%;
    border-collapse: collapse;
    color: #8bacc8;
  }
  
  .news-table th, .news-table td {
    border: 1px solid #8bacc8;
    padding: 8px;
    text-align: left;
  }
  
  .news-table th {
    background-color: #8bacc8;
    color: #333;
  }
  
  .news-img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 5px;
  }
  
  .edit-button, .delete-button {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .delete-button {
    background-color: #dc3545;
  }
  
  .edit-button:hover {
    background-color: #218838;
  }
  
  .delete-button:hover {
    background-color: #c82333;
  }
  
  /* Modal Styles */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    width: 770px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 35px 30px 30px 30px;
    border-radius: 15px;
    background: radial-gradient(100% 100% at 50% 0%, rgba(0, 255, 194, 0.15) 0%, rgba(7, 38, 61, 0) 80%), linear-gradient(256deg, #07263d 0%, #07243a 100%);
    color: white;
    z-index: 9999;
  }
  
  .save-button {
    background: linear-gradient(255deg, #00ffc2 0%, #00aa6d 100%);
    color: white;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .save-button:hover {
    background-color: #0056b3;
  }
  
  .close-button {
    background-color: #6c757d;
    color: white;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .close-button:hover {
    background-color: #5a6268;
  }
  </style>
  