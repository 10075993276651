<template>
    <div class="admin-bonus">
      <h1>Bonus Management</h1>
  
      <!-- Bonus Ekleme Formu -->
      <form @submit.prevent="addBonus" enctype="multipart/form-data">
        <h2>Add New Bonus</h2>
        <input v-model="newBonus.title" type="text" placeholder="Bonus Title" required />
        <input v-model="newBonus.description" type="text" placeholder="Bonus Description" required />
        <textarea v-model="newBonus.modalDescription" placeholder="Modal Description" required></textarea>
        <input v-model="newBonus.percentage" type="number" placeholder="Bonus Percentage" required />
        <input type="file" @change="onFileChange" accept="image/*" required />
        <select v-model="newBonus.bonusType" required>
          <option value="welcome">Welcome Bonus</option>
          <option value="freespin">Free Spin Bonus</option>
        </select>
        <button type="submit">Add Bonus</button>
      </form>
  
      <!-- Bonus Listesi -->
      <h2>Bonus List</h2>
      <table>
        <thead>
          <tr>
            <th>Title</th>
            <th>Description</th>
            <th>Modal Description</th>
            <th>Percentage</th>
            <th>Bonus Type</th>
            <th>Image</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="bonus in bonuses" :key="bonus._id">
            <td>{{ bonus.title }}</td>
            <td>{{ bonus.description }}</td>
            <td>{{ bonus.modalDescription }}</td>
            <td>{{ bonus.percentage }}%</td>
            <td>{{ bonus.bonusType }}</td>
            <td><img :src="bonus.img" alt="Bonus Image" width="100" /></td>
            <td>
              <button @click="editBonus(bonus)">Edit</button>
              <button @click="deleteBonus(bonus._id)">Delete</button>
            </td>
          </tr>
        </tbody>
      </table>
  
      <!-- Düzenleme Modali -->
      <div v-if="editMode" class="modal">
        <div class="modal-content">
          <h3>Edit Bonus</h3>
          <input v-model="editBonusData.title" type="text" placeholder="Title" required />
          <input v-model="editBonusData.description" type="text" placeholder="Description" required />
          <textarea v-model="editBonusData.modalDescription" placeholder="Modal Description" required></textarea>
          <input v-model="editBonusData.percentage" type="number" placeholder="Percentage" required />
          <input type="file" @change="onFileChangeEdit" accept="image/*" />
          <select v-model="editBonusData.bonusType" required>
            <option value="welcome">Welcome Bonus</option>
            <option value="freespin">Free Spin Bonus</option>
          </select>
          <button @click="updateBonus">Save Changes</button>
          <button @click="cancelEdit">Cancel</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        bonuses: [],
        newBonus: {
          title: '',
          description: '',
          modalDescription: '',
          percentage: 0,
          bonusType: 'welcome',
          img: null // Resim dosyası için yer
        },
        editBonusData: null,
        editMode: false,
        selectedImageFile: null, // Dosya seçimi için değişken
        selectedEditImageFile: null
      };
    },
    mounted() {
      this.fetchBonuses();
    },
    methods: {
      async fetchBonuses() {
        try {
          const response = await axios.get('https://play.rivobit.com/bonus');
          this.bonuses = response.data;
        } catch (error) {
          console.error('Error fetching bonuses:', error);
        }
      },
      onFileChange(e) {
        this.selectedImageFile = e.target.files[0]; // Dosya seçimi
      },
      async addBonus() {
        try {
          const formData = new FormData();
          formData.append('title', this.newBonus.title);
          formData.append('description', this.newBonus.description);
          formData.append('modalDescription', this.newBonus.modalDescription);
          formData.append('percentage', this.newBonus.percentage);
          formData.append('bonusType', this.newBonus.bonusType);
          formData.append('img', this.selectedImageFile); // Resim dosyasını ekle
  
          await axios.post('https://play.rivobit.com/bonus/add', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
          this.fetchBonuses();
          this.resetForm();
        } catch (error) {
          console.error('Error adding bonus:', error);
        }
      },
      onFileChangeEdit(e) {
        this.selectedEditImageFile = e.target.files[0]; // Düzenleme modunda dosya seçimi
      },
      async updateBonus() {
        try {
          const formData = new FormData();
          formData.append('title', this.editBonusData.title);
          formData.append('description', this.editBonusData.description);
          formData.append('modalDescription', this.editBonusData.modalDescription);
          formData.append('percentage', this.editBonusData.percentage);
          formData.append('bonusType', this.editBonusData.bonusType);
          if (this.selectedEditImageFile) {
            formData.append('img', this.selectedEditImageFile); // Eğer yeni bir resim seçildiyse ekle
          }
  
          await axios.put(`https://play.rivobit.com/bonus/${this.editBonusData._id}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
          this.fetchBonuses();
          this.editMode = false;
        } catch (error) {
          console.error('Error updating bonus:', error);
        }
      },
      async deleteBonus(id) {
        try {
          await axios.delete(`https://play.rivobit.com/bonus/${id}`);
          this.fetchBonuses();
        } catch (error) {
          console.error('Error deleting bonus:', error);
        }
      },
      resetForm() {
        this.newBonus = {
          title: '',
          description: '',
          modalDescription: '',
          percentage: 0,
          bonusType: 'welcome',
          img: null
        };
        this.selectedImageFile = null;
      },
      cancelEdit() {
        this.editMode = false;
        this.selectedEditImageFile = null;
      }
    }
  };
  </script>
  
  <style scoped>
  .admin-bonus {
    
    padding: 20px;
    
  }
  
  .admin-bonus h1 {
    text-align: center;
    color: #343a40;
    font-size: 32px;
    margin-bottom: 20px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  th, td {
    padding: 16px;
    border-bottom: 1px solid #e9ecef;
    text-align: left;
    color: #495057;
  }
  
  th {
    background-color: #007bff;
    color: white;
    font-weight: 600;
  }
  
  td {
    background-color: white;
  }
  
  button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    color: white;
    background-color: #007bff;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s ease;
  }
  
  .modal-content {
    background: #fff;
    padding: 30px;
    border-radius: 12px;
    width: 500px;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
    position: relative;
    text-align: center;
  }
  
  .modal-content h3 {
    color: #007bff;
    margin-bottom: 20px;
  }
  
  input[type="text"], textarea, input[type="number"] {
    width: 100%;
    padding: 12px;
    border: 1px solid #ced4da;
    border-radius: 6px;
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  textarea {
    resize: vertical;
  }
  
  .save-button {
    background-color: #007bff;
  }
  
  .save-button:hover {
    background-color: #0056b3;
  }
  
  .cancel-button {
    background-color: #6c757d;
    margin-left: 10px;
  }
  
  .cancel-button:hover {
    background-color: #5a6268;
  }
  </style>
  