<template>
    <div class="admin-support">
      <h1>Customer Services Management</h1>
  
      <!-- Müşteri Hizmetleri Ekleme Formu -->
      <form @submit.prevent="addCustomerService">
        <label for="platform">Platform:</label>
        <select v-model="newCustomerService.platform" required>
          <option value="Telegram">Telegram</option>
          <option value="WhatsApp">WhatsApp</option>
          <option value="Live Support">Live Support</option>
          <option value="Facebook">Facebook</option>
          <option value="YouTube">YouTube</option>
          <option value="X">X</option>
          <option value="TikTok">TikTok</option>
        </select>
  
        <label for="title">Title:</label>
        <input v-model="newCustomerService.title" type="text" required />
  
        <label for="link">Link:</label>
        <input v-model="newCustomerService.link" type="text" required />
  
        <label for="workingHours">Working Hours:</label>
        <input v-model="newCustomerService.workingHours" type="text" placeholder="09:00 - 18:00" required />
  
        <button type="submit">Add Customer Service</button>
      </form>
  
      <!-- Müşteri Hizmetleri Listesi -->
      <h2>Customer Services List</h2>
      <table>
        <thead>
          <tr>
            <th>Platform</th>
            <th>Title</th>
            <th>Link</th>
            <th>Working Hours</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="service in customerServices" :key="service._id">
            <td>{{ service.platform }}</td>
            <td>{{ service.title }}</td>
            <td>{{ service.link }}</td>
            <td>{{ service.workingHours }}</td>
            <td>
              <button @click="editCustomerService(service)">Edit</button>
              <button @click="deleteCustomerService(service._id)">Delete</button>
            </td>
          </tr>
        </tbody>
      </table>
  
      <!-- Edit Modal -->
      <div v-if="editMode" class="modal">
        <div class="modal-content">
          <h3>Edit Customer Service</h3>
          <label for="platform">Platform:</label>
          <select v-model="editCustomerServiceData.platform" required>
            <option value="Telegram">Telegram</option>
            <option value="WhatsApp">WhatsApp</option>
            <!-- Diğer platform seçenekleri -->
          </select>
  
          <label for="title">Title:</label>
          <input v-model="editCustomerServiceData.title" type="text" required />
  
          <label for="link">Link:</label>
          <input v-model="editCustomerServiceData.link" type="text" required />
  
          <label for="workingHours">Working Hours:</label>
          <input v-model="editCustomerServiceData.workingHours" type="text" placeholder="09:00 - 18:00" required />
  
          <button @click="updateCustomerService">Save Changes</button>
          <button @click="cancelEdit">Cancel</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        customerServices: [],
        newCustomerService: {
          platform: '',
          title: '',
          link: '',
          workingHours: ''
        },
        editCustomerServiceData: null,
        editMode: false
      };
    },
    mounted() {
      this.fetchCustomerServices();
    },
    methods: {
      async fetchCustomerServices() {
        const response = await axios.get('https://play.rivobit.com/customerservices');
        this.customerServices = response.data;
      },
      async addCustomerService() {
        try {
          await axios.post('https://play.rivobit.com/customerservices/add', this.newCustomerService);
          this.fetchCustomerServices();
          this.newCustomerService = { platform: '', title: '', link: '', workingHours: '' }; // Formu temizle
        } catch (error) {
          console.error('Error adding customer service:', error);
        }
      },
      editCustomerService(service) {
        this.editCustomerServiceData = { ...service };
        this.editMode = true;
      },
      async updateCustomerService() {
        try {
          await axios.put(`https://play.rivobit.com/customerservices/${this.editCustomerServiceData._id}`, this.editCustomerServiceData);
          this.fetchCustomerServices();
          this.editMode = false;
        } catch (error) {
          console.error('Error updating customer service:', error);
        }
      },
      async deleteCustomerService(id) {
        try {
          await axios.delete(`https://play.rivobit.com/customerservices/${id}`);
          this.fetchCustomerServices();
        } catch (error) {
          console.error('Error deleting customer service:', error);
        }
      },
      cancelEdit() {
        this.editMode = false;
      }
    }
  };
  </script>
  
  <style scoped>
  .admin-support {
    
    padding: 20px;
    font-family: 'Arial', sans-serif;
   
  }
  
  h1, h2 {
    background: linear-gradient(255deg, #00ffc2 0%, #00aa6d 100%), #a6cae8;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-align: center;
    margin-bottom: 20px;
    font-weight: 600;
  }
  
  form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    background-color: #022038;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  label {
    font-weight: 600;
    color: #8bacc8;
  }
  
  input[type="text"], select {
    width: 100%;
    padding: 10px;
    background: rgba(19, 66, 88, 0.25);
    border-radius: 6px;
    color: wheat;
    font-size: 14px;
  }
  
  button[type="submit"] {
    grid-column: 1 / -1;
    padding: 10px 20px;
    background: linear-gradient(255deg, #00ffc2 0%, #00aa6d 100%), #a6cae8;
    color: white;
    border: none;
    border-radius: 6px;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button[type="submit"]:hover {
    background-color: #0056b3;
  }
  
  table {
    width: 100%;
    margin-top: 40px;
    border-collapse: collapse;
  }
  
  th, td {
    padding: 15px;
    border-bottom: 1px solid #ddd;
    text-align: left;
  }
  
  th {
    background: rgba(19, 66, 88, 0.25);
    color: white;
    border-radius: 15px;
    font-weight: 600;
    text-transform: uppercase;
  }
  
  td {
    color: #8bacc8;
  }
  
 
  
  button {
    padding: 6px 12px;
    background: linear-gradient(255deg, #00ffc2 0%, #00aa6d 100%), #a6cae8;
    color: white;
    border: none;
    border-radius: 4px;
    margin-left: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  button.edit-button {
    background-color: #28a745;
  }
  
  button.edit-button:hover {
    background-color: #218838;
  }
  
  button.delete-button {
    background-color: #dc3545;
  }
  
  button.delete-button:hover {
    background-color: #c82333;
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    width: 770px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 35px 30px 30px 30px;
    border-radius: 15px;
    background: radial-gradient(100% 100% at 50% 0%, rgba(0, 255, 194, 0.15) 0%, rgba(7, 38, 61, 0) 80%), linear-gradient(256deg, #07263d 0%, #07243a 100%);
  }
  
  .modal h3 {
    margin-bottom: 20px;
    background: linear-gradient(250deg, #00ffc2 0%, #00aa6d 100%), #a6cae8;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-weight: 600;
  }
  
  .modal input[type="text"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    background: #1a4f63;
    border-radius: 6px;
  }
  
  .modal button {
    padding: 10px 15px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
    margin-bottom: 20px;
  }
  
  .modal button.save-button {
    background-color: #28a745;
    color: white;
  }
  
  .modal button.save-button:hover {
    background-color: #218838;
  }
  
  .modal button.cancel-button {
    background-color: #6c757d;
    color: white;
  }
  
  .modal button.cancel-button:hover {
    background-color: #5a6268;
  }
  </style>
  