<template>
    <div class="games-container">
      <h1>Oyun Yönetimi</h1>
  
      <!-- Arama Alanı -->
      <div class="search-bar">
        <input
          v-model="searchQuery.game_name"
          placeholder="Oyun Adı Ara"
          @input="fetchGames"
        />
        <input
          v-model="searchQuery.provider_code"
          placeholder="Sağlayıcı Kodu Ara"
          @input="fetchGames"
        />
      </div>
  
      <!-- Oyun Tablosu -->
      <table class="games-table">
        <thead>
          <tr>
            <th>Banner</th>
            <th>İsim</th>
            <th>Kod</th>
            <th>Sağlayıcı Kodu</th>
            <th>Tür</th>
            <th>Durum</th>
            <th>Oluşturulma Tarihi</th>
            <th>Güncellenme Tarihi</th>
            <th>İşlem</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="game in games" :key="game._id">
            <td><img :src="game.banner_url" alt="Banner" class="game-banner" /></td>
            <td>{{ game.game_name }}</td>
            <td>{{ game.game_code }}</td>
            <td>{{ game.provider_code }}</td>
            <td>{{ game.game_type }}</td>
            <td>{{ game.status }}</td>
            <td>{{ formatDate(game.createdAt) }}</td>
            <td>{{ formatDate(game.updatedAt) }}</td>
            <td>
              <button @click="editGame(game)">Düzenle</button>
            </td>
          </tr>
        </tbody>
      </table>
  
      <!-- Düzenleme Modalı -->
      <div v-if="selectedGame" class="modal">
        <div class="modal-content">
          <h2>Oyun Düzenle</h2>
          <form @submit.prevent="updateGame">
            <label>
              İsim:
              <input v-model="selectedGame.game_name" />
            </label>
            <label>
              Kod:
              <input v-model="selectedGame.game_code" />
            </label>
            <label>
              Sağlayıcı Kodu:
              <input v-model="selectedGame.provider_code" />
            </label>
            <label>
              Tür:
              <input v-model="selectedGame.game_type" />
            </label>
            <label>
              Durum:
              <select v-model="selectedGame.status">
                <option value="active">Aktif</option>
                <option value="inactive">Pasif</option>
              </select>
            </label>
            <button type="submit">Kaydet</button>
            <button @click="selectedGame = null">Kapat</button>
          </form>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    data() {
      return {
        games: [],
        searchQuery: {
          game_name: "",
          provider_code: "",
        },
        selectedGame: null,
      };
    },
    methods: {
      // Oyunları Backend'den Getir
      async fetchGames() {
        try {
          const response = await axios.get("/games/search", {
            params: this.searchQuery,
          });
          this.games = response.data.data;
        } catch (error) {
          console.error("Oyunlar alınamadı:", error);
        }
      },
      // Oyun Güncelleme İşlemi
      async updateGame() {
        try {
          const response = await axios.put(
            `/games/${this.selectedGame._id}`,
            this.selectedGame
          );
          this.fetchGames(); // Listeyi yenile
          this.selectedGame = null; // Modalı kapat
        } catch (error) {
          console.error("Oyun güncellenemedi:", error);
        }
      },
      // Oyun Düzenleme
      editGame(game) {
        this.selectedGame = { ...game }; // Seçili oyunu klonla
      },
      // Tarih Formatlama
      formatDate(date) {
        return new Date(date).toLocaleDateString();
      },
    },
    mounted() {
      this.fetchGames();
    },
  };
  </script>
  
  <style>
  .games-container {
    padding: 20px;
  }
  
  .search-bar input {
    margin-right: 10px;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .games-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .games-table th,
  .games-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
  }
  
  .games-table img {
    width: 100px;
    height: 60px;
    object-fit: cover;
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
  }
  </style>
  