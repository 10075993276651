<template>
    <div class="admin-sidebar" :class="{ 'sidebar-open': sidebarOpen }">
        <button v-on:click="toggleSidebar" class="button-toggle">
            <div class="button-value">{{ adminGetRoute }}</div>
            <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.5176 1.66411e-06L0.482354 8.43375e-08C0.0547936 9.58042e-09 -0.16302 0.516304 0.143533 0.822859L4.66115 5.34052C4.8467 5.52607 5.15325 5.52607 5.33888 5.34052L9.8565 0.822861C10.163 0.516306 9.94516 1.73887e-06 9.5176 1.66411e-06Z" />
            </svg>
        </button>
        <div class="sidebar-menu">
            <router-link to="/admin" v-on:click.native="toggleSidebar">Dashboard</router-link>
            <router-link to="/admin/users" v-on:click.native="toggleSidebar">Users</router-link>
            <router-link to="/admin/games" v-on:click.native="toggleSidebar">Games</router-link>
            <router-link to="/admin/cashier" v-on:click.native="toggleSidebar">Cashier</router-link>
            <router-link to="/admin/providers" v-on:click.native="toggleSidebar">Providers</router-link>
            <router-link to="/admin/promo" v-on:click.native="toggleSidebar">Promo</router-link>
            <router-link to="/admin/bonus" v-on:click.native="toggleSidebar">Bonuses</router-link>
            <router-link to="/admin/notice" v-on:click.native="toggleSidebar">Notice</router-link>
            <router-link to="/admin/news" v-on:click.native="toggleSidebar">News</router-link>
            <router-link to="/admin/support" v-on:click.native="toggleSidebar">Customer Services</router-link>
            <router-link to="/admin/leaderboard" v-on:click.native="toggleSidebar">Leaderboard</router-link>
            <router-link to="/admin/affiliates" v-on:click.native="toggleSidebar">Affiliates</router-link>
            <router-link to="/admin/rain" v-on:click.native="toggleSidebar">Rain</router-link>   
            <router-link to="/admin/boxes" v-on:click.native="toggleSidebar">Boxes</router-link>
            <router-link to="/admin/filter" v-on:click.native="toggleSidebar">Filter</router-link>
            <router-link to="/admin/settings" v-on:click.native="toggleSidebar">Settings</router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AdminSidebar',
    data() {
        return {
            sidebarOpen: true, // Start with the sidebar open
        };
    },
    methods: {
        toggleSidebar() {
            this.sidebarOpen = !this.sidebarOpen;
        }
    },
    computed: {
        adminGetRoute() {
            let route = this.$route.name.replace('Admin', '');
            if(route === 'Stats') {
                route = 'Stats Book';
            }
            return route;
        }
    },
    created() {
        let self = this;
        document.addEventListener('click', function(event) {
            if(!self.$el.contains(event.target) && self.sidebarOpen) {
                self.toggleSidebar();
            }
        });
    }
}
</script>

<style scoped>
.admin-sidebar {
    width: 250px;
    position: fixed; /* Fixed position for sidebar */
    top: 80px;
    left: 0; /* Align to the left */
    height: 100vh; /* Full height */
    background: #022038; /* Sidebar background color */
    transition: transform 0.3s ease; /* Smooth transition */
    transform: translateX(0); /* Show sidebar */
    z-index: 15;
}

.admin-sidebar.sidebar-open {
    transform: translateX(0); /* Keep sidebar open */
}

.admin-sidebar .button-toggle {
    width: 100%;
    height: 47px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    border-radius: 5px;
    background: rgba(19, 66, 88, 0.25);
}

.admin-sidebar .button-toggle svg {
    fill: #49687d;
    transition: all 0.3s ease;
}

.admin-sidebar .button-toggle .button-value {
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
}

.admin-sidebar .sidebar-menu {
    padding-top: 20px; /* Add some spacing from the button */
}

.admin-sidebar .sidebar-menu a {
    width: 100%;
    height: 47px;
    display: flex;
    align-items: center;
    padding: 0 14px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
    color: #8bacc8;
    transition: 0.3s ease;
}

.admin-sidebar .sidebar-menu a:hover {
    background: rgba(19, 66, 88, 0.15);
}

@media only screen and (max-width: 650px) {
    .admin-sidebar {
        width: 100%;
        margin-top: 15px;
    }
}
</style>
