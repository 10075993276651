<template>
    <div class="admin-notice">
      <h1 class="title">Bildirim Gönder</h1>
  
      <!-- Toplu Bildirim Gönderme Formu -->
      <form class="bulk-form" @submit.prevent="sendBulkNotice">
        <h2 class="form-header">Toplu Bildirim Gönder</h2>
        <div class="form-group">
          <label for="bulk-title">Başlık:</label>
          <input type="text" v-model="bulkTitle" id="bulk-title" required />
        </div>
        <div class="form-group">
          <label for="bulk-message">Mesaj:</label>
          <textarea v-model="bulkMessage" id="bulk-message" required></textarea>
        </div>
        <button type="submit" class="bulk-button">Tüm Kullanıcılara Gönder</button>
      </form>
  
     
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        users: [],
        bulkTitle: '',
        bulkMessage: '',
        individualTitle: '',
        individualMessage: '',
        showModal: false,
        selectedUserId: null,
        searchQuery: '', // Kullanıcı arama terimi
      };
    },
    mounted() {
      this.fetchUsers();
    },
    computed: {
      // Kullanıcı arama terimine göre filtrelenmiş listeyi döner
      filteredUsers() {
        return this.users.filter(user =>
          user.username.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      },
    },
    methods: {
      async fetchUsers() {
        try {
          const response = await axios.get('http://192.168.1.111:5000/notices/users');
          this.users = response.data;
        } catch (error) {
          console.error('Error fetching users:', error);
        }
      },
      async sendBulkNotice() {
        try {
          await axios.post('http://192.168.1.111:5000/notices/bulk', {
            title: this.bulkTitle,
            message: this.bulkMessage,
          });
          alert('Toplu bildirim gönderildi.');
          this.bulkTitle = '';
          this.bulkMessage = '';
        } catch (error) {
          console.error('Error sending bulk notice:', error);
        }
      },
      openModal(userId) {
        this.selectedUserId = userId;
        this.showModal = true;
      },
      async sendIndividualNotice() {
        try {
          await axios.post('http://192.168.1.111:5000/notices/individual', {
            title: this.individualTitle,
            message: this.individualMessage,
            recipientId: this.selectedUserId,
          });
          alert('Bireysel bildirim gönderildi.');
          this.individualTitle = '';
          this.individualMessage = '';
          this.showModal = false;
        } catch (error) {
          console.error('Error sending individual notice:', error);
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .admin-notice {
    
    font-family: Arial, sans-serif;
    
    border-radius: 8px;
    
  }
  
  .title {
    text-align: center;
    background: linear-gradient(255deg, #00ffc2 0%, #00aa6d 100%), #a6cae8;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    margin-bottom: 20px;
    
  }
  
  .bulk-form {
    
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  }
  
  .form-header,
  .user-header {
    margin-bottom: 10px;
    color: #8bacc8;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    color: #8bacc8;
  }
  
  input[type="text"],
  textarea {
    width: 100%;
    padding: 10px;
    background: rgba(19, 66, 88, 0.25);
    border-radius: 4px;
    font-size: 16px;
  }
  
  textarea {
    resize: vertical;
  }
  
  .bulk-button,
  .individual-button,
  .send-button {
    background: linear-gradient(255deg, #00ffc2 0%, #00aa6d 75%);
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  .bulk-button:hover,
  .individual-button:hover,
  .send-button:hover {
    background-color: #0056b3;
  }
  
  .user-list {
    list-style: none;
    padding: 0;
  }
  
  .user-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #e9ecef;
  }
  
  .username {
    color: #fff;
  }
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 5px;
    width: 400px;
    position: relative;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #000;
  }
  
  .modal-title {
    margin: 0;
    color: #343a40;
  }
  
  @media (max-width: 600px) {
    .admin-notice {
      padding: 15px;
    }
  
    .bulk-button,
    .individual-button,
    .send-button {
      width: 100%;
    }
  
    .modal-content {
      width: 90%;
    }
  }
  </style>
  